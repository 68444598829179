<template>
    <SpaceDataList :spaceData="educationSpaces" :spaceType="spaceTypes.EDUCATION_SPACE" spaceIcon="school" headerTitle="Courses"></SpaceDataList>
</template>

<script>
import { mapState } from 'vuex'
import { enumsData } from '@/mixins/enums'
const SpaceDataList = () => import('../components/TheHomeSpaceTable')

export default {
    mixins: [enumsData],
    components: { SpaceDataList },
    computed: {
        ...mapState('orgStore', ['educationSpaces'])
    }
}
</script>
