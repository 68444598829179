var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SpaceDataList", {
    attrs: {
      spaceData: _vm.educationSpaces,
      spaceType: _vm.spaceTypes.EDUCATION_SPACE,
      spaceIcon: "school",
      headerTitle: "Courses"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }